<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center justify-content-between">
        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
          {{ title }}
        </h3>
        <router-link class="btn btn-success btn-sm" to="trader-form">
          <i class="fas fa-plus"></i>
        </router-link>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="traders"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.sector") }}</th>
          <th>{{ $t("tables.orders_count") }}</th>
          <th>{{ $t("tables.couriers_count") }}</th>
          <th>{{ $t("tables.items_count") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img
                  :alt="row.item.name + 'Image'"
                  :src="`https://nqal.co/LiveNqal/public/uploads/traders/${row.item.logo}`"
                />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.sector }}
          </td>
          <td class="budget">
            {{ row.item.orders_cnt }}
          </td>
          <td class="budget">
            {{ row.item.couriers_cnt }}
          </td>
          <td class="budget">
            {{ row.item.items_cnt }}
          </td>
          <td class="budget">
            <span v-if="row.item.enable == 1" class="badge badge-dot">
              <i class="bg-success"></i> {{ $t("tables.enable") }}
            </span>
            <span v-else class="badge badge-dot">
              <i class="bg-warning"></i> {{ $t("tables.disable") }}
            </span>
          </td>
          <td class="budget text-right">
            <router-link
              :to="`/traders/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <select
              class="btn btn-success btn-sm text-left"
              @change="chgTraderStatus(row.item, $event)"
            >
              <option value="">{{ $t("tables.change status") }}</option>
              <option :value="1">{{ $t("tables.enable") }}</option>
              <option :value="0">{{ $t("tables.disable") }}</option>
            </select>
            <router-link
              :to="`/trader-form/${row.item.id}`"
              class="btn btn-success btn-sm"
              ><i class="fas fa-edit"></i
            ></router-link>
            <!-- <router-link
              :to="`/couriers/${row.item.id}`"
              class="btn btn-danger btn-sm"
              ><i class="fas fa-trash"></i
            ></router-link> -->
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "traders-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      traders: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getTraders("admin/administration/traders/indexPaginate?page=1");
  },
  watch: {
    page: function () {
      this.getTraders(
        "admin/administration/traders/indexPaginate?page=" + this.page
      );
    },
  },
  methods: {
    getTraders: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            this.traders = response.data.data;
            this.page = response.data.meta.current_page;
            this.total_pages = response.data.meta.last_page;
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Change Trader Status
    chgTraderStatus: function ($trader) {
      this.loading = true;
      this.newStatus = event.target.value;
      axios
        .post("admin/administration/traders/enable", {
          _method: "put",
          trader_id: $trader.id,
          enable: this.newStatus,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 1) {
              // let updatedId = response.data.data[0];
              this.traders.filter((trader) => {
                // if (courier.id === updatedId.id) {
                if (trader.id === $trader.id) {
                  trader.enable = this.newStatus;
                }
              });
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            } else {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.message,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
